import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";

import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatMenuModule } from "@angular/material/menu";
import { MatSelectModule } from "@angular/material/select";
import { MatStepperModule } from "@angular/material/stepper";
import { MatTabsModule } from "@angular/material/tabs";
import { MatTooltipModule } from "@angular/material/tooltip";
import { TranslateModule } from "@ngx-translate/core";

import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MAT_DATE_LOCALE } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { DialogNotasEvaluacionComponent } from "@fuse/components/dialog-notas-evaluacion/dialog-notas-evaluacion.component";
import { FuseSharedModule } from "@fuse/shared.module";
import { SharedComponentsModule } from "app/components/shared-components.module";
import { NgxDaterangepickerMd } from "ngx-daterangepicker-material";
import { DialogInfoComponent } from "./dialog-info/dialog-info.component";
import { DialogObjetivosHistoricoComponent } from "./objetivos-history/objetivos-history.component";
import { PanelColaboradoresActualesComponent } from "./panel-colaboradores-actuales/panel-colaboradores-actuales.component";
import { ColaboratorCardComponent } from "./panel-control/colaborator-card/colaborator-card.component";
import { PanelControlComponent } from "./panel-control/panel-control.component";
import { PrivateFeedbackDialogComponent } from "./panel-control/private-feedback-dialog/private-feedback-dialog.component";
import { TransferDialogComponent } from "./panel-control/transfer-dialog/transfer-dialog.component";
import { SiedComponent } from "./sied.component";

const routes = [
	{
		path: "sied",
		component: SiedComponent,
	},
	{
		path: "sied/control-panel",
		component: PanelControlComponent,
	},
	{
		path: "sied/colaboradores-actuales",
		component: PanelColaboradoresActualesComponent,
	},
	{
		path: "objetivos",
		component: SiedComponent,
	},
];

@NgModule({
	declarations: [
		SiedComponent,
		DialogInfoComponent,
		PanelControlComponent,
		PanelColaboradoresActualesComponent,
		ColaboratorCardComponent,
		TransferDialogComponent,
		PrivateFeedbackDialogComponent,
		DialogNotasEvaluacionComponent,
		DialogObjetivosHistoricoComponent,
	],
	imports: [
		RouterModule.forChild(routes),

		TranslateModule,
		MatInputModule,
		MatFormFieldModule,
		MatSelectModule,
		MatIconModule,
		MatButtonModule,
		MatTooltipModule,
		MatDialogModule,
		MatTabsModule,
		FontAwesomeModule,
		MatStepperModule,
		MatMenuModule,
		MatDatepickerModule,
		MatProgressSpinnerModule,
		MatAutocompleteModule,
		MatPaginatorModule,

		NgxDaterangepickerMd.forRoot(),

		FuseSharedModule,
		SharedComponentsModule,
	],
	providers: [{ provide: MAT_DATE_LOCALE, useValue: "es-ES" }],
})
export class SiedModule {}
